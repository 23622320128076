import { useTranslation } from 'react-i18next'

import { MaintenanceTemplateProps } from '../../templates/MaintenanceTemplate'

const useProps = () => {
  const { t } = useTranslation()

  const maintenanceProps: MaintenanceTemplateProps = {
    title: t('maintenance_title'),
    description: t('maintenance_text'),
  }

  return maintenanceProps
}

export default useProps
