import React, { FC } from 'react'
import NextImage from 'next/image'

import * as SC from './styled'

export type MaintenanceTemplateProps = {
  title?: string
  description?: string
}

const MaintenanceTemplate: FC<MaintenanceTemplateProps> = ({
  title,
  description,
}) => {
  return (
    <SC.Container>
      <SC.Content>
        <NextImage
          width={330}
          height={165}
          src={'/static/assets/images/404_unpluged.png'}
          alt={'image'}
        />
        {title && <SC.Title dangerouslySetInnerHTML={{ __html: title }} />}
        {description && (
          <SC.Description dangerouslySetInnerHTML={{ __html: description }} />
        )}
        <SC.CrossesTop width={120} />
        <SC.CrossesBottom width={120} />
      </SC.Content>
    </SC.Container>
  )
}

export default MaintenanceTemplate
