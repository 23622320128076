import { FC } from 'react'
import { isMaintenance } from 'src/helpers/SSRHelpers'
import { useRouter } from 'next/router'
import MaintenancePage from 'src/pages/_maintenance'

const MaintenanceGuard: FC<any> = ({ children, ...pageProps }) => {
  const router = useRouter()
  const isMaintenanceEnabled = isMaintenance(router.query.maintenanceToken)

  if (isMaintenanceEnabled) {
    return <MaintenancePage {...pageProps} />
  }

  return children
}

export default MaintenanceGuard
