import styled from 'styled-components'

import { mq, sizes } from '../../theme'
import Icon, { Icons } from '../../components/Icon'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Content = styled.div`
  max-width: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 12px 38px rgba(167, 177, 192, 0.2);
  border-radius: 2rem;
  position: relative;
  margin: 6rem auto;
  padding: 3rem 1.5rem;

  @media ${mq(sizes.tablet)} {
    margin: 15rem auto 20rem auto;
    padding: 7rem;
    max-width: 86rem;
  }
`

export const Title = styled.h1`
  ${({ theme }) => theme.textStyles.firaSans3Xl};
  color: ${({ theme }) => theme.colors.bluePrimary};
  text-align: center;
  margin: 2rem 0 0.3rem 0;
  text-transform: uppercase;

  @media ${mq(sizes.mobile)} {
    ${({ theme }) => theme.textStyles.firaSans6Xl};
  }
`

export const CrossesTop = styled(Icon).attrs({ icon: Icons.crosses })`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`

export const CrossesBottom = styled(Icon).attrs({ icon: Icons.crosses })`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
`

export const Description = styled.p`
  ${({ theme }) => theme.textStyles.montserratSm};
  color: ${({ theme }) => theme.colors.greyFields};
  text-align: center;
  margin-bottom: 5rem;

  @media ${mq(sizes.mobile)} {
    ${({ theme }) => theme.textStyles.montserratXl};
  }
`
