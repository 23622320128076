import { NextPage } from 'next'

import { initWithLangAndLabels } from '../i18n'
import { PageProps } from '../relay/Common/PageProps'
import useProps from '../relay/Maintenance/useProps'
import getConfiguration from '../rest/services/getConfiguration'
import MaintenanceTemplate from '../templates/MaintenanceTemplate'

export type MaintenancePageProps = PageProps

const MaintenancePage: NextPage<MaintenancePageProps> = () => {
  const maintenanceProps = useProps()

  return <MaintenanceTemplate {...maintenanceProps} />
}

export async function getStaticProps(): Promise<{
  props: MaintenancePageProps
  revalidate: number
}> {
  const i18n = initWithLangAndLabels('fr', {})
  const [configuration] = await Promise.all([getConfiguration()])

  return {
    props: {
      metas: {} as any,
      i18n,
      configuration,
    },
    revalidate: 60 * 60, // every hour max
  }
}

export default MaintenancePage
